import React from "react";
import styled from "styled-components/macro";
import AppLogin from "./components/AppLogin";
import Home from "./components/Home";
import Start from "./components/Start";

function App() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Start />
    </div>
  );
}

export default App;
