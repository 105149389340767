import React, { useState } from "react";
import Home from "./Home";
import AppLogin from "./AppLogin";

function Start(props) {
  const [logged, setLogged] = useState(false);
  const [token, setToken] = useState("");

  return (
    <div>
      {!logged && <AppLogin setLogged={setLogged} setToken={setToken} />}
      {logged && <Home token={token} />}
    </div>
  );
}

export default Start;
