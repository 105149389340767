import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import { v4 as uuidv4 } from "uuid";

import styled from "styled-components/macro";

import S3FileUpload from "react-s3";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AppImageUpload(props) {
  const [imageFolder, setImageFolder] = useState("");
  const [images, setImages] = useState([]);
  const [thump, setThump] = useState([]);

  const classes = useStyles();

  const handleChange = (e) => {
    setImageFolder(e.target.value);
  };
  const uploadImages = () => {
    const config = {
      bucketName: "b2b-shopping-app",
      dirName: imageFolder,
      region: "eu-central-1",
      accessKeyId: process.env.AWS_ID,
      secretAccessKey: process.env.AWS_SECRET,
    };
    for (let i = 0; i < images.length; i++) {
      S3FileUpload.uploadFile(images[i], config)
        .then((data) => setThump([...thump, data.location]))
        .catch((err) => console.error(err));
    }
  };

  const handleChangeStatus = (e) => {
    // console.log(status, meta, file);
    console.log(e.target.files.length);
    console.log(e.target.files);
    setImages(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      //   setImages([...images, e.target.files[i]]);
      console.log(i);
    }
  };

  //   const getUploadParams = ({ meta }) => {
  //     return { url: "https://localhost:3000/download" };
  //   };

  const handleSubmit = () => {
    imageFolder ? uploadImages(images) : alert("Please select a Target Folder");
  };

  return (
    <Main>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">
          Target Folder
        </InputLabel>
        <Select
          native
          value={imageFolder}
          onChange={(e) => handleChange(e)}
          label="Upload Image"
          inputProps={{
            name: "Upload Image",
            id: "outlined-age-native-simple",
          }}
        >
          <option key={uuidv4()} value="categoryimages">
            Category Images
          </option>
          <option key={uuidv4()} value="itemimages">
            Item Images
          </option>
          <option key={uuidv4()} value="sliderimages">
            Slider Images
          </option>
        </Select>
      </FormControl>

      <div
        style={{
          width: "400px",
          marginLeft: 30,
          marginTop: 10,
        }}
      >
        <FormControl>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              marginBottom: 10,
              height: 40,
              borderRadius: 6,
              border: "1px solid lightgrey",
            }}
          >
            <input
              type="file"
              multiple
              onChange={(e) => handleChangeStatus(e)}
              accept="image/*,audio/*,video/*"
            />
          </div>
          <StyledButton
            variant="contained"
            type="submit"
            color="default"
            onClick={handleSubmit}
          >
            SUBMIT
          </StyledButton>
          <div style={{ marginTop: 20 }}>
            {thump.length > 0 && <h1>Images has been uploaded</h1>}
            {thump.length > 0 &&
              thump.map((th) => (
                <img style={{ width: 100 }} key={uuidv4()} src={th} />
              ))}
          </div>
        </FormControl>
      </div>
    </Main>
  );
}

const Main = styled.div``;
const StyledButton = styled(Button)`
  margin: 20px;
  width: 150px;
  padding: 15;
`;

export default AppImageUpload;
