import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import axios from "axios";

const mongoUrl = "https://b2bshoppingapi.herokuapp.com";
// const mongoUrl = "localhost:5000/api";

function AppLogin({ setLogged, setToken }) {
  const initialValues = { name: "", password: "" };
  const LoginValidation = Yup.object().shape({
    name: Yup.string().min(2).required(),
    password: Yup.string().min(2).max(16).required(),
  });

  const handleSubmit = (values) => {
    const config = {
      method: "post",
      url: `${mongoUrl}/api/admin/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: process.env.REACT_APP_ADMIN_USER,
        password: process.env.REACT_APP_ADMIN_PASSWORD,
      },
    };
    axios(config)
      .then(function (response) {
        setToken(response.data.token);
        setLogged(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Main style={{ display: "flex", flexDirection: "row" }}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            style={{ marginBottom: 10 }}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.email && formik.errors.name}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            style={{ marginBottom: 10 }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            variant="contained"
            type="submit"
            color="default"
            style={{ padding: 15 }}
          >
            L O G I N
          </Button>
        </div>
      </form>
    </Main>
  );
}

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default AppLogin;
