import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import * as XLSX from "xlsx";

import styled from "styled-components/macro";

import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import AppImageUpload from "./AppImageUpload";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const tables = [
  "App_Assortment",
  "App_Assortment_Item",
  "App_Attribute_1",
  "App_Attribute_2",
  "App_Attribute_3",
  "App_Attribute_4",
  "App_Category_Images",
  "App_Category_Translation",
  "App_Customer",
  "App_Customer_Price_Group",
  "App_Customer_Web_User",
  "App_Item",
  "App_Item_Images",
  "App_Item_Translation",
  "App_Language",
  "App_Item_Unit_Of_Measure",
  "App_Order_Header",
  "App_Order_Line",
  "App_Sales_Price",
  "App_Setup",
  "App_Unit_Of_Measure",
];
function Home({ token }) {
  const [orderHeader, setOrderHeader] = useState(null);
  const [orderLine, setOrderLine] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [dataVisible, setDataVisible] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);

  const [visible, setVisible] = useState(false);

  const [excelTable, setExcelTabel] = useState();
  const [jsonTable, setJsonTable] = useState();

  const [articles, setArticels] = useState(null);

  const [state, setState] = useState("");
  const [allData, setAllData] = useState([]);

  const classes = useStyles();

  const mongoUrl = "https://b2bshoppingapi.herokuapp.com";
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const handleChange = (e) => {
    const table = e.target.value;
    console.log("Selected ", table);
    setState(table);
  };

  const getData = (table) => {
    const username = process.env.REACT_APP_MS_USER;
    const password = process.env.REACT_APP_MS_PASSWORD;
    const url = `https://agaz-sth17-dev.westeurope.cloudapp.azure.com:4443/agiles-Dynamics-NAV-10-0-DE-DEV-ODATA/OData/Company(%27agiles%20dynamics%20Food%20GmbH%27)/`;
    const login = Buffer.from(`${username}:${password}`).toString("base64");
    const auth = `NTLM ${login}`;

    table === "App_Order_Header"
      ? getAppOrderHeader()
      : table === "App_Order_Line"
      ? getAppOrderLine()
      : fetch(`${url}${table}?$format=json`, {
          credentials: "include",
        })
          .then((res) => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result);
              console.log(result);
            },

            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
  };

  const postAppAssortment = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appassortment/add`,
        {
          No: item.No,
          Description: item.Description,
        },
        config
      )
    );
  };

  const postAppAssortmentItem = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appassortmentitem/add`,
        {
          Assortment_No: item.Assortment_No,
          Item_No: item.Item_No,
          New: item.New,
          Blocked: item.Blocked,
          Offer: item.Offer,
        },
        config
      )
    );
  };

  const postAppAttribute1 = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appattribute1/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppAttribute2 = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appattribute2/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppAttribute3 = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appattribute3/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppAttribute4 = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appattribute4/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppCategory = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcategory/add`,
        {
          Code: item.Code,
          Description: item.Description,
          Item_Main_Category_Code: item.Item_Main_Category_Code,
          Level: item.Level,
          newCategory: item.newCategory,
        },
        config
      )
    );
  };
  const postAppCategoryImages = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcategoryimages/add`,
        {
          Code: item.Code,
          Image: item.Image,
        },
        config
      )
    );
  };
  const postAppCategoryTranslation = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcategorytranslation/add`,
        {
          Code: item.Code,
          Language: item.Language,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppCustomer = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcustomer/add`,
        {
          No: item.No,
          Name: item.Name,
          Name_2: item.Name_2,
          Address: item.Address,
          Address_1: item.Address_1,
          Country_Code: item.Country_Code,
          Zip_Code: item.Zip_Code,
          City: item.City,
          Cust_Price_Group_Code: item.Cust_Price_Group_Code,
          Language_Code: item.Language_Code,
          Assortment_No: item.Assortment_No,
        },
        config
      )
    );
  };
  const postAppCustomerAssortment = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcustomerassortment/add`,
        {
          Customer_No: item.Customer_No,
          Assortment_No: item.Assortment_No,
        },
        config
      )
    );
  };
  const postAppCustomerPriceGroup = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcustomerpricegroup/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppCustomerWebUser = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appcustomerwebuser/add`,
        {
          debtor_number: item.debtor_number,
          user_number: item.user_number,
          password: item.password,
        },
        config
      )
    );
  };

  const postAppItem = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appitem/add`,
        {
          No: item.No,
          Attribute_1_Code: item.Attribute_1_Code,
          Attribute_2_Code: item.Attribute_2_Code,
          Attribute_3_Code: item.Attribute_3_Code,
          Attribute_4_Code: item.Attribute_4_Code,
          Item_Class_Code: item.Item_Class_Code,
          Item_Main_Category_Code: item.Item_Main_Category_Code,
          Item_Category_Code: item.Item_Category_Code,
          Search_Description: item.Search_Description,
          Inventory_Qty: item.Inventory_Qty,
          Base_Unit_Code: item.Base_Unit_Code,
          Sales_Unit_Code: item.Sales_Unit_Code,
          Blocked: item.Blocked,
          Description: item.Description,
          VAT_Percentage: item.VAT_Percentage,
        },
        config
      )
    );
  };
  const postAppItemImages = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appitemimages/add`,
        {
          Item_Id: item.Item_Id,
          Image: item.Image,
        },
        config
      )
    );
  };
  const postAppItemTranslation = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appitemtranslation/add`,
        {
          No: item.No,
          Language: item.Language,
          Description: item.Description,
        },
        config
      )
    );
  };
  const postAppItemUnitOfMeasure = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appitemunitofmeasure/add`,
        {
          Item_No: item.Item_No,
          Unit_of_Measure_Code: item.Unit_of_Measure_Code,
          Qty_per_Unit: item.Qty_per_Unit,
          Net_Weight: item.Net_Weight,
        },
        config
      )
    );
  };
  const postAppLanguage = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/applanguage/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };

  const getAppOrderHeader = () => {
    axios
      .get(`${mongoUrl}/api/apporderheader/get`, config)
      .then((response) => {
        setOrderHeader(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postAppOrderHeader = () => {};

  const getAppOrderLine = () => {
    axios
      .get(`${mongoUrl}/api/apporderline/`, config)
      .then((response) => {
        setOrderLine(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postAppOrderLine = () => {};

  const postAppSalesPrice = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appsalesprice/add`,
        {
          Sales_Type: item.Sales_Type,
          Sales_Code: item.Sales_Code,
          Item_No: item.Item_No,
          Sales_Unit_Code: item.Sales_Unit_Code,
          Starting_Date: item.Starting_Date,
          Minimum_Quantity: item.Minimum_Quantity,
          Sales_Price: item.Sales_Price,
          Ending_Date: item.Ending_Date,
          default_sales_price: item.default_sales_price,
        },
        config
      )
    );
  };
  const postAppSetup = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appsetup/add`,
        {
          Primary_Key: item.Primary_Key,
          Attribute_1_Name: item.Attribute_1_Name,
          Attribute_2_Name: item.Attribute_2_Name,
          Attribute_3_Name: item.Attribute_3_Name,
          Attribute_4_Name: item.Attribute_4_Name,
          Item_Class_Name: item.Item_Class_Name,
          Item_Main_Category_Name: item.Item_Main_Category_Name,
          Item_Category_Name: item.Item_Category_Name,
        },
        config
      )
    );
  };
  const postAppUnitOfMeasure = () => {
    jsonTable.forEach((item) =>
      axios.post(
        `${mongoUrl}/api/appunitofmeasure/add`,
        {
          Code: item.Code,
          Description: item.Description,
        },
        config
      )
    );
  };

  const handlePushClick = (table) => {
    pushCollection(table);
  };

  const deleteCollection = (collection) => {
    const config = {
      method: "delete",
      url: `${mongoUrl}/${collection}/delete`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    // axios.delete(config).then();
  };

  const pushCollection = (collection) => {
    switch (collection) {
      case "App_Assortment":
        deleteCollection("appassortment");
        postAppAssortment();
        break;
      case "App_Assortment_Item":
        deleteCollection("appassortmentitem");
        postAppAssortmentItem();
        break;
      case "App_Attribute_1":
        deleteCollection();
        postAppAttribute1();
        break;
      case "App_Attribute_2":
        deleteCollection();
        postAppAttribute2();
        break;
      case "App_Attribute_3":
        deleteCollection();
        postAppAttribute3();
        break;
      case "App_Attribute_4":
        deleteCollection();
        postAppAttribute4();
        break;
      case "App_Category":
        deleteCollection();
        postAppCategory();
        break;
      case "App_Category_Images":
        deleteCollection();
        postAppCategoryImages();
        break;
      case "App_Category_Translation":
        deleteCollection();
        postAppCategoryTranslation();
        break;
      case "App_Customer":
        deleteCollection();
        postAppCustomer();
        break;
      case "App_Customer_Assortment":
        deleteCollection();
        postAppCustomerAssortment();
        break;
      case "App_Customer_Price_Group":
        deleteCollection();
        postAppCustomerPriceGroup();
        break;
      case "App_Customer_Web_User":
        deleteCollection();
        postAppCustomerWebUser();
        break;
      case "App_Item":
        deleteCollection();
        postAppItem();
        break;
      case "App_Item_Images":
        deleteCollection();
        postAppItemImages();
        break;
      case "App_Item_Translation":
        deleteCollection();
        postAppItemTranslation();
        break;
      case "App_Item_Unit_Of_Measure":
        deleteCollection();
        postAppItemUnitOfMeasure();
        break;
      case "App_Language":
        deleteCollection();
        postAppLanguage();
        break;
      case "App_Order_Header":
        deleteCollection();
        postAppOrderHeader();
        break;
      case "App_Order_Line":
        deleteCollection();
        postAppOrderLine();
        break;
      case "App_Sales_Price":
        deleteCollection();
        postAppSalesPrice();
        break;
      case "App_Setup":
        deleteCollection();
        postAppSetup();
        break;
      case "App_Unit_Of_Measure":
        deleteCollection();
        postAppUnitOfMeasure();
        break;
    }
  };
  const handleInputChange = (e) => {
    let fileObj = e.target.files[0];
    const filename = e.target.files[0].name.split(".")[0];
    setState(filename);

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
      });
      let rowObject;
      workbook.SheetNames.forEach((sheet) => {
        rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      });
      setJsonTable(rowObject);
      setArticels(rowObject);
    };

    if (rABS) reader.readAsBinaryString(fileObj);
    else reader.readAsArrayBuffer(fileObj);

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setExcelTabel({
          cols: resp.cols,
          rows: resp.rows,
        });
        // console.log(resp);
      }
    });
  };

  return (
    <Main>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{
          marginTop: "30px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledButton
          variant="contained"
          type="submit"
          color="default"
          onClick={() => setDataVisible(!dataVisible)}
          style={{ margin: "10px" }}
        >
          DATA
        </StyledButton>
        <StyledButton
          variant="contained"
          type="submit"
          color="default"
          onClick={() => setImageVisible(!imageVisible)}
        >
          IMAGE
        </StyledButton>
      </FormControl>
      {dataVisible && (
        <section
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Data Table
            </InputLabel>
            <Select
              native
              value={state}
              onChange={(e) => handleChange(e)}
              label="Data Table"
              inputProps={{
                name: "Data Table",
                id: "outlined-age-native-simple",
              }}
            >
              {tables.map((table) => (
                <option key={uuidv4()} value={table}>
                  {table}
                </option>
              ))}
            </Select>

            <StyledButton
              variant="contained"
              type="submit"
              color="default"
              style={{ marginTop: "10px" }}
              onClick={() => getData(state)}
            >
              GET FROM DB
            </StyledButton>
            <StyledButton
              variant="contained"
              type="submit"
              color="default"
              style={{ marginTop: "10px" }}
              onClick={() => setVisible(!visible)}
            >
              GET FROM FILE
            </StyledButton>
          </FormControl>
        </section>
      )}
      {dataVisible && visible && (
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Input
              type="file"
              onChange={(e) => handleInputChange(e)}
              style={{
                padding: "10px",
                marginLeft: "10px",
                border: "1px solid lightgrey",
                borderRadius: 6,
              }}
            />
            <StyledButton
              variant="contained"
              type="submit"
              color="default"
              style={{ margin: "10px" }}
              onClick={() => handlePushClick(state)}
            >
              PUSH To APP DB
            </StyledButton>
          </FormControl>
        </div>
      )}

      {imageVisible && (
        <section style={{ marginTop: "30px" }}>
          <AppImageUpload></AppImageUpload>
        </section>
      )}
      <section>
        {excelTable && (
          <OutTable
            data={excelTable.rows}
            columns={excelTable.cols}
            tableClassName="ExcelTable2007"
            tableHeaderRowClass="heading"
          />
        )}
      </section>
      {/* <section style={{ margin: 30 }}>
        {articles.length > 0 &&
          articles.map((article) => (
            <Card
              key={uuidv4()}
              style={{
                overflow: "hidden",
                background: "white",
                display: "flex",
              }}
            >
              <div style={{ margin: 5 }}>
                {Object.keys(article).map((item) => (
                  <div
                    key={uuidv4()}
                    style={{
                      margin: 5,
                      padding: 4,
                      maxHeight: "20px",
                      overflow: "hidden",
                      lineHeight: "20px",
                    }}
                  >
                    {!item ? "/" : item}
                  </div>
                ))}
              </div>
              <div style={{ margin: 5 }}>
                {Object.values(article).map((value) => (
                  <div
                    key={uuidv4()}
                    style={{
                      margin: 5,
                      padding: 4,
                      maxHeight: "20px",
                      overflow: "hidden",
                      lineHeight: "20px",
                    }}
                  >
                    {value === false
                      ? "false"
                      : value === true
                      ? "true"
                      : !value
                      ? "-"
                      : value}
                  </div>
                ))}
              </div>
            </Card>
          ))}
      </section> */}
    </Main>
  );
}

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  margin: 20px;
  width: 150px;
  padding: 15;
`;
// const Card = styled.div`
//   display: flex;
//   margin: 30px;
//   padding: 20px;
//   background: white;
//   justify-content: flex-start;
//   box-shadow: 0 0 10px 2px #e8e2e1;
//   border-radius: 20px;
// `;
export default Home;
